import { AutocompleteInputProps, AutocompleteInput, ReferenceInput } from "react-admin"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const QuotaRefInput = (props: Props) => {
  const filterToQuery = (searchText: string) => ({ search: searchText })
  const { source = "quota_id", ...rest } = props

  return (
    <ReferenceInput reference="booking_quotas" source={source} perPage={100}>
      <AutocompleteInput
        filterToQuery={filterToQuery}
        optionText="title"
        debounce={500}
        {...rest}
      />
    </ReferenceInput>
  )
}

export { QuotaRefInput }
