import React from "react"
import { AutocompleteInput, AutocompleteInputProps, ReferenceInput } from "react-admin"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const OwnerRefInput: React.FC<Props> = (props) => {
  const filterToQuery = (searchText: string) => ({ search: searchText })
  return (
    <ReferenceInput reference="customers" source={props.source ?? "customer_id"} perPage={100}>
      <AutocompleteInput
        filterToQuery={filterToQuery}
        optionText={(choice) => `${choice.display_name} (${choice.short_id})`}
        debounce={500}
        {...props}
      />
    </ReferenceInput>
  )
}

export { OwnerRefInput }
